import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import Footer from "../Footer.js";

const InstituteInfoTable = () => {

  const containerStyle = {
    padding: '10px',
    paddingTop: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(224, 224, 224)',
  };

  return (
    <>
    <ScrollToTopButton />
      <TopBar />
      <NavBar
        // foundationName="Shree Dhanlaxmi Foundation"
        instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
        instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
        address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
        logoSrc={logo}
        buttonText="Contact Us"
      />
    <div style={containerStyle}>
      <table className="table table-success table-striped">
      <tbody>
          <tr className="p-5">
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Affiliations:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">The institute is affiliated with Maharashtra University of Health Sciences, Nashik</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Establishment year:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">2021</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Management:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">Private Un- Aided</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Location type:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">Rural</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Total staff:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">6</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>‌Laboratory:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">5</p>
            </td>
          </tr>
          <tr>
            <td className="p-5 pt-2 pb-1">
              <p>
                <h5>Class rooms:</h5>
              </p>
            </td>
            <td>
              <p className="pt-3">4</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
    </>
  );
};

export default InstituteInfoTable;
