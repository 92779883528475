// App.js
import React, {useState} from "react";
import AnnexureList from "./AnnexureList";
import AnnexureDetails from "./AnnexureDetails";
import "../css/MuhsMandate.css";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import Footer from "../Footer.js";

const MuhsMandate = () => {
  const handleButtonClick = () => {
    console.log("Button Clicked!");
  };

  const [selectedAnnexure, setSelectedAnnexure] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const annexures = [
    {
      number: 1,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "Annexure I",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Annexure-I.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Annexure I",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure I.pdf",
            },
            {
              id: 2,
              name: "Annexure IA",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure I A.jpeg",
            },
            {
              id: 2,
              name: "Annexure II Infra",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annex II Infra.jpeg",
            },
            {
              id: 3,
              name: "Annexure IB",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure I B.jpeg",
            },
            {
              id: 4,
              name: "Annexure IC",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annex I.pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 1,
              name: "Seat Matrix",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure 1.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 2,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "College Infrastructre",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Annexure II.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Infastructure Details and Hospital Details",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure II.pdf",
            },
            {
              id: 2,
              name: "Registration Certificate",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure II A.pdf",
            },
            {
              id: 3,
              name: "Annexure II B",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure II B.pdf",
            },
            {
              id: 4,
              name: "Annexure II C",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure II C.pdf",
            },
            {
              id: 5,
              name: "Building Plan",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Building plan.pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 3,
              name: "Infastructure Details",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure II.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 3,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "College Infrastructre",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Annexure II.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Annexure III",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure III.pdf",
            },
            {
              id: 2,
              name: "Trust Deed",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure III A.pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 1,
              name: "Trust Deed",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure III.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 4,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "Library",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Annexure IV.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Library Details",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure IV (Updated).pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 1,
              name: "Library Details",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure IV.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 5,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "Casualty Inventory",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Casualty Inventory.pdf",
            },
            {
              id: 2,
              name: "equip list 1",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Equip List sahyadri hosp karad.xlsx",
            },
            {
              id: 3,
              name: "icu inventory",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/icu inventory.pdf",
            },
            {
              id: 4,
              name: "Shree Hospital 002",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Shree Hospital 002.pdf",
            },
            {
              id: 5,
              name: "Annexure-V",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/New Doc 2022-07-27 14.15.46_9.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Clinical Material in Hospital",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure V.pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 1,
              name: "Clinical Material in Hospital",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure V-5 Clinical Material Hospital.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 6,
      years: [
        {
          year: "2022-2023",
          files: [
            {
              id: 1,
              name: "Annexure-VI",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2021-2022/Annexure VI.pdf",
            },
          ],
        },
        {
          year: "2023-2024",
          files: [
            {
              id: 1,
              name: "Annexure VI",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure VI.pdf",
            },
            {
              id: 2,
              name: "Invoice",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure VI A.pdf",
            },
            {
              id: 3,
              name: "Annexure VI A",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure - VI...pdf",
            },
            {
              id: 4,
              name: "List of Equipment",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2023-2024/Annexure VI (Updated).pdf",
            },
          ],
        },
        {
          year: "2024-2025",
          files: [
            {
              id: 1,
              name: "Equipment & Instrument",
              link:
                process.env.PUBLIC_URL +
                "assets/pdfs/annexures/annexures/2024-2025/Annexure VI.pdf",
            },
          ],
        },
      ],
    },
    {
      number: 7,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "Annexure VII", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/Annexure VII.pdf" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Chart of Teaching Staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure VII.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Chart of Teaching Staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure VII - 7.pdf" },
          ],
        },
      ],
    },
    {
      number: 8,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "Annexure VIII", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/Annexure VIII.pdf" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Subjectwise teaching staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annex VII Teching Staff with Photos.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Subjectwise approved and not approved teaching staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure VIII Teaching Staff (Approved Non Approved).pdf" },
          ],
        },
      ],
    },
    {
      number: 9,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "List of non teching & ancillary staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/List of non teching & ancillary staff 001.jpg" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Non Teaching Staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure IX Non Teaching (Updated).pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Non teaching staff", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure IX-9.pdf" },
          ],
        },
      ],
    },
    {
      number: 10,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "Annexure X", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/Annexure X.pdf" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Guest Faculty", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure X.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Guest Faculty", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/annexure X part time guest faculty.pdf" },
          ],
        },
      ],
    },
    {
      number: 11,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "Annex XI", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/Annex XI.pdf" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Webinar & workshop", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure XI (Updated).pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Webinar/ Workshops", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure XI -11.pdf" },
          ],
        },
      ],
    },
    {
      number: 12,
      years: [
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Annexure XII", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure- XII...pdf" },
            { id: 2, name: "June 2023", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/June 2023.pdf" },
            { id: 3, name: "July 2023", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/July 2023.pdf" },
            { id: 4, name: "Attendance Biometric", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Biometric Attendan.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "MUHS Mandate", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure XII.pdf" },
          ],
        },
      ],
    },
    {
      number: 13,
      years: [
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Ann XIII Aishe Cert", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Ann XIII Aishe Cert.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Aishe Certificate", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Aishe Cer Annexure XIII-13.pdf" },
          ],
        },
      ],
    },
    {
      number: 14,
      years: [
        {
          year: '2022-2023',
          files: [
            { id: 1, name: "Annex 14", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2021-2022/Annex 14.pdf" },
          ],
        },
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Annexure XIV (A B C)", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure- XIV (A B C)1.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Exam related information", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure XIV.pdf" },
          ],
        },
      ],
    },
    {
      number: 15,
      years: [
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Annexure XV Fellowship", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure XV.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Fellowship and certificate LIC format with annexures", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure XV.pdf" },
          ],
        },
      ],
    },
    {
      number: 16,
      years: [
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Annexure XVI Ph.D", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure XVI.pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Ph. D. LIC Format", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Annexure XVI.pdf" },
          ],
        },
      ],
    },
    {
      number: 17,
      years: [
        {
          year: '2023-2024',
          files: [
            { id: 1, name: "Annexure XVII", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2023-2024/Annexure - XVII...pdf" },
          ],
        },
        {
          year: '2024-2025',
          files: [
            { id: 1, name: "Declaration", link: process.env.PUBLIC_URL + "assets/pdfs/annexures/annexures/2024-2025/Declaration Annexure XVII-17.pdf" },
          ],
        },
      ],
    },
    // Add more annexures as needed
  ];

  const handleSelectAnnexure = (annexure) => {
    setSelectedAnnexure(annexure);
    setSelectedYear(null); // Reset selected year when changing annexure
  };

  const handleSelectYear = (year) => {
    setSelectedYear(year);
  };

  return (
    <>
      <div className="App">
        <ScrollToTopButton />
        <TopBar />
        <NavBar
          // foundationName="Shree Dhanlaxmi Foundation"
          instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
          instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
          address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
          logoSrc={logo}
          buttonText="Contact Us"
          onButtonClick={handleButtonClick}
        />
        <div className="annex_list">
          <div className="muhs_content">
            <AnnexureList
              annexures={annexures}
              onSelectAnnexure={handleSelectAnnexure}
            />
          </div>
          <div className="muhs_content">
            {selectedAnnexure && selectedAnnexure.years && (
              <div className="year-list">
                <h3>Select Year</h3>
                <ul>
                  {selectedAnnexure.years.map((yearData) => (
                    <li
                      key={yearData.year}
                      onClick={() => handleSelectYear(yearData.year)}
                    >
                      {yearData.year}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="muhs_content">
            {selectedYear && selectedAnnexure && (
              <div className="annexure-details-container">
                <AnnexureDetails
                  selectedAnnexure={selectedAnnexure}
                  selectedYear={selectedYear}
                />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MuhsMandate;
