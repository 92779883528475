import React, {useState} from "react";
import Slider from "react-slick";
import "./css/AboutUs.css";
import image1 from "./assets/slider/clg_photo.jpg";
import image2 from "./assets/slider/d7432a31-4e2f-4c54-9b07-e468e6585f9d.jpg";
import image3 from "./assets/slider/b25e3e1d-c880-474a-b000-3fbff7135ed3.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutUsSection = () => {
  const [showExtraText, setShowExtraText] = useState(false);

  // Sample content for the about us section
  const aboutUsContent = `
    Late Bhaiyasaheb Ghorpade Institute of Physiotherapy is one of the first MUHS Physiotherapy college in karad (Masur) dist-satara established in 2021.It started to serve students with best physiotherapy knowledge offer course like under graduate.It is located in karad in karad(Masur) dist-Satara.This institute is affiliated with Maharastra university of health sciences,Nashik.Sayadhri hospital karad is the parent hospital of this institute.
  `;

  const extraText = `
    Affiliated hospital of this institute are shree hospital karad, Erram hospital karad,Karad hospital karad. Physiotherapy is one of the allied health profession which promotes to restore health through physical examination, diagnosis,Prognosis,Patient education,Physical interventation and rehabilitation and preventation of disease. It was estabilished by personality with Late bhaiya saheb Ghorpade-to impart high quality Physiotherapy education through innovation and interactive learning process.
  `;

  // Sample images for the slider
  const sliderImages = [image1, image2, image3];
  // const sliderImages = [
  //   "https://images.pexels.com/photos/2246476/pexels-photo-2246476.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   "https://images.pexels.com/photos/1042423/pexels-photo-1042423.jpeg?auto=compress&cs=tinysrgb&w=600",
  //   "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&w=600",
  // ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleReadMoreClick = () => {
    setShowExtraText(!showExtraText);
  };

  return (
    <div className="about-us-section">
      <div className="about-us-content">
        <h2>About Us</h2>
        <p>{aboutUsContent}</p>
        {showExtraText && <p>{extraText}</p>}
        <button onClick={handleReadMoreClick}>
          {showExtraText ? "Read Less" : "Read More"}
        </button>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {sliderImages.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Slider ${index + 1}`}
                style={{width: "100%", height: "400px", borderRadius: "15px"}}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AboutUsSection;
