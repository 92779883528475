const Categories_data = {
    html: {
        category: 'HTML'
    },
    css: {
        category: 'CSS'
    },
    javascript: {
        category: 'JavaScript'
    },
    web_development: {
        category: 'Web Development'
    },
    all_category: {
        category: 'All Categories'
    },

}
export default Categories_data;