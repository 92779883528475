import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import AboutUsSection from "../AboutUs.js";
import Facilities from "../Facilities.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import PhotoGallery from "../PhotoGallery.js";
import ContactForm from "../ContactForm.js";
import MapEmbed from "../MapEmbed.js";
import Footer from "../Footer.js";

function Home() {
  const handleButtonClick = () => {
    console.log("Button Clicked!");
  };

  const dynamicQuickLinks = [
    {text: "Advertisement for Faculty Position", url: process.env.PUBLIC_URL + "assets/pdfs/quicklinks/Advertisement for Faculty vacancy.jpeg"},
    {text: "Vacancy Position list after Institute Round 1", url: "#"},
    {text: "Vacancy Position list after stray Vacancy Round 2", url: "#"},
    {text: "Admission Notification", url: "#"},
    {text: "Seat Matrix", url: process.env.PUBLIC_URL + "assets/pdfs/quicklinks/Seat Metrix.pdf"},
  ];
  return (
    <>
      <div className="App">
        <ScrollToTopButton />
        <TopBar />
        <NavBar
          // foundationName="Shree Dhanlaxmi Foundation"
          instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
          instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
          address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
          logoSrc={logo}
          buttonText="Contact Us"
          onButtonClick={handleButtonClick}
        />
        <AboutUsSection />
        <Facilities />
        <PhotoGallery quickLinks={dynamicQuickLinks} maxPhotos={20}/>
        <ContactForm />
        <MapEmbed />
        <Footer />
      </div>
    </>
  );
}

export default Home;
