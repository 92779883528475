import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import GalleryImages from "./GalleryImages.jsx";
import Footer from "../Footer.js";

const Gallery = () => {
  const handleButtonClick = () => {
    console.log("Button Clicked!");
  };

  return (
    <>
      <ScrollToTopButton />
      <TopBar />
      <NavBar
        // foundationName="Shree Dhanlaxmi Foundation"
        instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
        instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
        address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
        logoSrc={logo}
        buttonText="Contact Us"
        onButtonClick={handleButtonClick}
      />
      <GalleryImages/>
      <Footer />
    </>
  );
};

export default Gallery;
