// Logo.js
import React from 'react';

const Logo = ({ logoSrc, altText }) => {
    // const logoStyle = {
    //   width: '100px', // Set the desired width
    //   height: '100px', // Maintain the aspect ratio
    //   // You can adjust the width and height values according to your preference
    // };
    return <img src = { logoSrc }
    alt = { altText }
    style = {
        { width: '130px', height: '180px', marginLeft: '0px' } }
    />;
};

export default Logo;