import React from "react";
import {Link} from "react-router-dom";
import "./css/PhotoGallery.css"; // Include your CSS file for styling

const PhotoGalleryContainer = ({quickLinks,maxPhotos}) => {
  const photoContext = require.context(
    "../photos",
    false,
    /\.(png|jpe?g|svg)$/
  );
  // const photos = photoContext.keys().map(photoContext);
  const allPhotos = photoContext.keys().map(photoContext);

  // Slice the array to get a specified number of photos
  const limitedPhotos = allPhotos.slice(0, maxPhotos);

  return (
    <div className="g-container">
      <div className="g-section1">
        <div>
          <h2>Photo Gallery</h2>
        </div>
        <div className="gallery-container">
          <div className="photo-gallery">
            {limitedPhotos.map((photo, index) => (
              <div key={index} className="gallery-item-container">
                <img
                  src={photo}
                  alt={`${index + 1}`}
                  className="gallery-item"
                />
              </div>
            ))}
            <div className="button-container">
            <Link to="/visit_gallery">
              <button className="visit-button">Visit</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="g-section2">
        <div>
          <h2>Quick Links</h2>
        </div>
        <div className="quick-links">
          <ul>
          {quickLinks.map((link, index) => (
            <li key={index}><a  href={link.url} target="_blank" rel="noopener noreferrer">
              {link.text}
            </a>
            </li>
          ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PhotoGalleryContainer;
