// AnnexureDetails.js
import React from 'react';
import FileList from './FileList';

const AnnexureDetails = ({ selectedAnnexure, selectedYear }) => {
  const years = selectedAnnexure.years || [];
  const selectedYearData = years.find((yearData) => yearData.year === selectedYear);

  return (
    <div className='annexdetails'>
      <h2>Annexure {selectedAnnexure.number}</h2>
      {selectedYearData ? (
        <div>
          <h3>Year {selectedYearData.year}</h3>
          <FileList files={selectedYearData.files} />
        </div>
      ) : (
        <p>Please select a year</p>
      )}
    </div>
  );
};

export default AnnexureDetails;
