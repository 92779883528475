// FileList.js
import React from "react";

const FileList = ({files}) => {
  const handleOpenFile = (file) => {
    if (file.link) {
      window.open(file.link, "_blank");
    } else {
      console.error("No direct link provided for this file.");
    }
  };
  return (
    <div className="file_annex_list">
      <ul file_annex_list>
        {files.map((file) => (
          <li key={file.id} onClick={() => handleOpenFile(file)}>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
