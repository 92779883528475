import React from 'react';

const MapEmbed = () => {
  return (
    <div style={{ width: '100%', height: '450px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121834.8147212249!2d74.10672153757251!3d17.395561495120475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1811cdd69599b%3A0x6ecb4b759570beaa!2sLate%20Bhaiyyasaheb%20Ghorpade%20Institute%20of%20Physiotherapy%2C%20Malwadi%20(Masur)%2C%20Tal.%20Karad%2C%20Dist.%20Satara!5e0!3m2!1sen!2sin!4v1706443180765!5m2!1sen!2sin"
        width="96%"
        height="100%"
        style={{ border: '5px solid #ffcc00', boxShadow: '9px 9px 3px 333333' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
