import React from 'react';
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import Footer from "../Footer.js";
import "../css/Committee.css"

class FileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [
        { name: 'Constituition of Local Management Committee', url: process.env.PUBLIC_URL + "assets/pdfs/Committee/Constituition of Local Management Committee.pdf" },
        { name: 'Anti-discrimination Committee 2022-2023', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Anti-discrimination Committee 2022-2023.pdf' },
        { name: 'Constitution of anti-ragging Comnmittee 2023-24', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Constitution of anti-ragging Comnmittee 2023-24.pdf' },
        { name: 'Constitution of Sexual Harassment Committee 2023-24', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Constitution of Sexual Harassment Committee 2023-24.pdf' },
        { name: 'Grievance RedresSal Committee 2022-2023', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Grievance RedresSal Committee 2022-2023.pdf' },
        { name: 'Grievance Redressal Committee 2023-2024', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Grievance Redressal Committee 2023-2024.pdf' },
        { name: 'Student_Council_and_Welfare_Committee_2022-23', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Student_Council_and_Welfare_Committee_2022-23.pdf' },
        { name: 'Time Table', url: process.env.PUBLIC_URL + 'assets/pdfs/Committee/Time Table.pdf' },
        // Add more files as needed
      ]
    };
  }

  render() {
    return (
        <>
        <ScrollToTopButton />
        <TopBar />
        <NavBar
          // foundationName="Shree Dhanlaxmi Foundation"
          instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
          instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
          address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
          logoSrc={logo}
          buttonText="Contact Us"
        />
        <div className="file-table-container">
          <table className="file-table">
            <thead>
              <tr>
                <th>Name Of Committee</th>
                <th>Composition</th>
              </tr>
            </thead>
            <tbody>
              {this.state.files.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Footer />
        </>
      );
  }
}

export default FileTable;
