import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './components/pages/Contact';
import Category from './components/pages/Category';
import Home from './components/pages/Home';
import Categories_data from './components/pages/Categories_data';
import MuhsMandate from './components/pages/MuhsMandate';
import FRA from './components/pages/FRA'
import IRI from './components/pages/IRI.jsx';
import InstituteInfoTable from './components/pages/Institute.jsx'
import HealthFacilitiesComponent from './components/pages/Facility.jsx'
import Gallery from './components/pages/Gallery.jsx'
import Committee from './components/pages/Committee.jsx'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/muhsmandate' element={<MuhsMandate />} />
        <Route path='/FRA' element={<FRA />} />
        <Route path='/iri' element={<IRI />} />
        <Route path='/instituteinfotable' element={<InstituteInfoTable />} />
        <Route path='/healthfacilitiescomponent' element={<HealthFacilitiesComponent />} />
        <Route path='/committee' element={<Committee />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/visit_gallery' element={<Gallery />} />
        <Route path='/html' element={<Category data={Categories_data.html} />} />
        <Route path='/css' element={<Category data={Categories_data.css} />} />
        <Route path='/javascript' element={<Category data={Categories_data.javascript} />} />
        <Route path='/web-development' element={<Category data={Categories_data.web_development} />} />
        <Route path='/category' element={<Category data={Categories_data.all_category} />} />
      </Routes>
    </Router>

  )
}

export default App;