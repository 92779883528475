import React, { useState } from "react";
import "../css/GalleryImages.css"; // Include your CSS file for styling

const PhotoGallery = () => {
  const photoContext = require.context(
    "../../photos",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const photos = photoContext.keys().map(photoContext);

  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handleImageClick = (index) => {
    setSelectedPhoto(index);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="g-container_1">
      <div className="g-section1_1">
        <div>
          <h2>Photo Gallery</h2>
        </div>
        <div className="gallery-container_1">
          <div className="photo-gallery_1">
            {photos.map((photo, index) => (
              <div
                key={index}
                className="gallery-item-container_1"
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={photo}
                  alt={`${index + 1}`}
                  className="gallery-item_1"
                />
              </div>
            ))}
          </div>
        </div>
        {selectedPhoto !== null && (
          <div className="modal" onClick={handleCloseModal}>
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <img
                src={photos[selectedPhoto]}
                alt={`${selectedPhoto + 1}`}
                className="modal-image"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoGallery;
