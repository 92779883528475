import React, {useState} from "react";
import DropDown from "./DropDown";
import {Link} from "react-router-dom";
import "./css/MenuBar.css";

function MenuBar() {
  const [click, setClick] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [admissionDropdown, setAdmissionDropdown] = useState(false);
  const [facilitiesDropdown, setFacilitiesDropdown] = useState(false);
  const [departmentsDropdown, setDepartmentsDropdown] = useState(false);
  const [academicsDropdown, setAcademicsDropdown] = useState(false);
  const [moreDropdown, setMoreDropdown] = useState(false);

  const changeClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterAbout = () => {
    setAboutDropdown(true);
  };

  const onMouseLeaveAbout = () => {
    setAboutDropdown(false);
  };

  const onMouseEnterAdmission = () => {
    setAdmissionDropdown(true);
  };

  const onMouseLeaveAdmission = () => {
    setAdmissionDropdown(false);
  };

  const onMouseEnterFacilities = () => {
    setFacilitiesDropdown(true);
  };

  const onMouseLeaveFacilities = () => {
    setFacilitiesDropdown(false);
  };

  const onMouseEnterDepartments = () => {
    setDepartmentsDropdown(true);
  };

  const onMouseLeaveDepartments = () => {
    setDepartmentsDropdown(false);
  };

  const onMouseEnterAcademics = () => {
    setAcademicsDropdown(true);
  };

  const onMouseLeaveAcademics = () => {
    setAcademicsDropdown(false);
  };

  const onMouseEnterMore = () => {
    setMoreDropdown(true);
  };

  const onMouseLeaveMore = () => {
    setMoreDropdown(false);
  };

  const aboutMenuItems = [
    {title: "Institute", path: "/instituteinfotable"},
    {title: "Affilation", path: "/about/option2"},
    {title: "University", path: "/about/option1"},
    {title: "Principal Message", path: "/about/option2"},
    {title: "Mission", path: "/about/option1"},
    {title: "Vision", path: "/about/option2"},
    // Add more menu items as needed
  ];

  const AdmissionMenuItems = [
    {title: "Institutional Round Information", path: "/iri"},
    {title: "Eligibility", path: "/category/category2"},
    {title: "Scholarships", path: "/category/category2"},
    // Add more menu items as needed
  ];

  const FacilitiesMenuItems = [
    {title: "Infrastruture", path: "/healthfacilitiescomponent"},
    {title: "Transport", path: "/category/category2"},
    {title: "Canteen", path: "/category/category1"},
    {title: "Hostel", path: "/category/category2"},
    {title: "Computer Lab", path: "/category/category1"},
    {title: "Internet", path: "/category/category2"},
    // Add more menu items as needed
  ];
  const DepartmentsMenuItems = [
    {title: "Library-Book Lab/DigitalLab", path: "/category/category1"},
    {title: "Laboratory", path: "/category/category2"},
    // Add more menu items as needed
  ];

  const AcademicsMenuItems = [
    {title: "Faculties", path: "/category/category1"},
    {title: "Syllabus", path: "/category/category2"},
    {title: "Academic Calender", path: "/category/category1"},
    {title: "Extension Activities", path: "/category/category2"},
    // Add more menu items as needed
  ];

  const MoreMenuItems = [
    {title: "Student Corner", path: "/category/category1"},
    {title: "Commititee's", path: "/committee"},
    {title: "Gallary", path: "/gallery"},
    // Add more menu items as needed
  ];

  return (
    <>
      <section>
        <nav className="navbar-second">
          {/* <Link to='/' className='logo'><i className='fas fa-home' /> Pro-Blog </Link> */}

          <div className="menu-icon" onClick={changeClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>

          <ul className={click ? "nav-side-menu start" : "nav-side-menu"}>
            <li className="nav-items">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                {" "}
                Home{" "}
              </Link>
            </li>

            <li
              className="nav-items"
              onMouseEnter={onMouseEnterAbout}
              onMouseLeave={onMouseLeaveAbout}
            >
              <Link to="/" className="nav-links">
                {" "}
                About <i className="fas fa-caret-down" />
                {aboutDropdown && <DropDown menuItems={aboutMenuItems} />}
              </Link>
            </li>

            <li className="nav-items">
              <Link to="/FRA" className="nav-links" onClick={closeMobileMenu}>
                {" "}
                FRA {" "}
              </Link>
            </li>

            <li className="nav-items">
              <Link to="/muhsmandate" className="nav-links" onClick={closeMobileMenu}>
                {" "}
                MUHS Mandate{" "}
              </Link>
            </li>

            <li
              className="nav-items"
              onMouseEnter={onMouseEnterAdmission}
              onMouseLeave={onMouseLeaveAdmission}
            >
              <Link className="nav-links">
                {" "}
                Admission<i className="fas fa-caret-down" />
                {admissionDropdown && <DropDown menuItems={AdmissionMenuItems} />}
              </Link>
            </li>

            <li
              className="nav-items"
              onMouseEnter={onMouseEnterFacilities}
              onMouseLeave={onMouseLeaveFacilities}
            >
              <Link className="nav-links" onClick={closeMobileMenu}>
                {" "}
                Facilities<i className="fas fa-caret-down" />
                {facilitiesDropdown && <DropDown menuItems={FacilitiesMenuItems} />}
              </Link>
            </li>

            <li
              className="nav-items"
              onMouseEnter={onMouseEnterDepartments}
              onMouseLeave={onMouseLeaveDepartments}
            >
              <Link className="nav-links" onClick={closeMobileMenu}>
                {" "}
                Department<i className="fas fa-caret-down" />
                {departmentsDropdown && <DropDown menuItems={DepartmentsMenuItems} />}
              </Link>
            </li>
            
            <li
              className="nav-items"
              onMouseEnter={onMouseEnterAcademics}
              onMouseLeave={onMouseLeaveAcademics}
            >
              <Link className="nav-links" onClick={closeMobileMenu}>
                {" "}
                Academics<i className="fas fa-caret-down" />
                {academicsDropdown && <DropDown menuItems={AcademicsMenuItems} />}
              </Link>
            </li>

            <li
              className="nav-items"
              onMouseEnter={onMouseEnterMore}
              onMouseLeave={onMouseLeaveMore}
            >
              <Link className="nav-links" onClick={closeMobileMenu}>
                {" "}
                More<i className="fas fa-caret-down" />
                {moreDropdown && <DropDown menuItems={MoreMenuItems} />}
              </Link>
            </li>
          </ul>
        </nav>
      </section>
    </>
  );
}

export default MenuBar;
