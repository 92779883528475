import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
    // Reset form data
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <>
    <h2>Contact Us</h2>
    <form
      onSubmit={handleSubmit}
      style={{
        maxWidth: '600px',
        margin: '0 auto 60px auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#333333',
        color: 'white',
      }}
    >
        
      <div style={{ marginBottom: '20px' }}>
        <label
          htmlFor="name"
          style={{
            display: 'block',
            marginBottom: '5px',
            fontSize: '16px',
          }}
        >
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          style={{
            width: '96%',
            padding: '8px 8px',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            color: 'black',
          }}
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label
          htmlFor="email"
          style={{
            display: 'block',
            marginBottom: '5px',
            fontSize: '16px',
          }}
        >
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          style={{
            width: '96%',
            padding: '8px 8px',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            color: 'black',
          }}
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label
          htmlFor="message"
          style={{
            display: 'block',
            marginBottom: '5px',
            fontSize: '16px',
          }}
        >
          Message:
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          style={{
            width: '96%',
            padding: '8px 8px',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            color: 'black',
          }}
        />
      </div>

      <button
        type="submit"
        style={{
          backgroundColor: '#00854b',
          color: '#fff',
          padding: '10px 15px',
          fontSize: '16px',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
      >
        Submit
      </button>
    </form>
    </>
  );
};

export default ContactForm;
