// TopBar.js
import React from 'react';
import "./css/TopBar.css";
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="social-icons">
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" style={linkStyles}>
          <FaFacebook />
        </a>
        <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer" style={linkStyles}>
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" style={linkStyles}>
          <FaInstagram />
        </a>
      </div>
      {/* Additional elements (e.g., admission open buttons) can be added here */}
    </div>
  );
};

// const topBarStyles = {
//     backgroundColor: '#53134A',
//     backgroundImage: 'linear-gradient(to right, #00d277b7, #00d277b7 )',
//     color: '#fff',
//     padding: '10px',
//     display: 'flex',
//     justifyContent: 'flex-end',
//   };

  const linkStyles = {
    color: 'white',
    padding: '0 10px',
  };


export default TopBar;
