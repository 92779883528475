import React from "react";
import "./css/Facilities.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHeartbeat, faFlaskVial, faSchool } from "@fortawesome/free-solid-svg-icons";
import image1 from "./assets/slider/4674709c-578c-44db-bd84-e5f898cf909d.jpg";
import image2 from "./assets/slider/d7432a31-4e2f-4c54-9b07-e468e6585f9d.jpg";
import image3 from "./assets/slider/ffb146a4-d84f-403d-bf7c-6d64406aa262.jpg";


const Facility = () => {
  return (
    <div className="facility-container">
      <h2>Our Facilities</h2>
      <p>We take pride in providing the following facilities</p>

      <div className="facility-sections">
        <div className="facility-container-in">
          <div className="facility-img">
            <img
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfYiPKomG1W_6mZ_z3HRNb01WFMqZjr2b3ng&usqp=CAU"
              src={image2}
              alt="Facility 1"
              style={{width: "250px", height: "188px"}}
            />
          </div>
          <div className="facility-card">
          <FontAwesomeIcon icon={faSchool} className="f-icon"/>
            <h3>ClassRoom</h3>
            <p>
              Discover a vibrant academic atmosphere within our classrooms.
            </p>
            <button>➭</button>
          </div>
        </div>

        <div className="facility-container-in">
          <div className="facility-img">
            <img
              src={image1}
              alt="Facility 1"
              style={{width: "250px", height: "188px"}}
            />
          </div>
          <div className="facility-card">
            <FontAwesomeIcon icon={faFlaskVial} className="f-icon"/>
            <h3>Laboratory</h3>
            <p>
            Experience learning through discovery in our purpose-built labs.
            </p>
            <button>➭</button>
          </div>
        </div>

        <div className="facility-container-in">
          <div className="facility-img">
            <img
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8xN1GuWG3UFNOssRSHIbKR423PPsO0qGHqw&usqp=CAU"
              src={image3}
              alt="Facility 1"
              style={{width: "250px", height: "188px"}}
            />
          </div>
          <div className="facility-card">
            <FontAwesomeIcon icon={faHeartbeat} className="f-icon"/>
            <h3>Healthcare</h3>
            <p>
            Discover superior healthcare at Sahyadri Hospital Karad, our parent institution.
            </p>
            <button>➭</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facility;
