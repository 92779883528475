import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import MapEmbed from "../MapEmbed.js";
import Footer from "../Footer.js";
import "../css/Contact.css";
import ContactForm from "../ContactForm.js";


function Contact() {
  const handleButtonClick = () => {
    console.log("Button Clicked!");
  };
  return (
    <>
      <div className="App">
        <ScrollToTopButton />
        <TopBar />
        <NavBar
          // foundationName="Shree Dhanlaxmi Foundation"
          instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
          instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
          address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
          logoSrc={logo}
          buttonText="Contact Us"
          onButtonClick={handleButtonClick}
        />
        <MapEmbed />
        <div className="contact-container">
          <div className="address-section">
            <h2>REACH US:</h2>
            <p>At- Malwadi, Post- Masur Tal - Karad</p>
            <p>Dist - Satara</p>
          </div>

          <div className="phone-section">
            <h2>PHONE:</h2>
            <p>Mr. V.R. Shinde: 7507811122</p>
            <p>Mr. Mahadik: 8999728869</p>
          </div>

          <div className="email-section">
            <h2>Email:</h2>
            <p>physiomasur@gmail.com</p>
          </div>
        </div>
        <ContactForm />
        <Footer />
      </div>
    </>
  );
}

export default Contact;
