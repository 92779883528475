import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import Footer from "../Footer.js";

const HealthFacilitiesComponent = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Optional: Adjust the height based on your layout requirements
  };

  const tableStyle = {
    borderCollapse: "collapse",
    width: "80%", // Adjust the width as needed
  };

  return (
    <>
      <ScrollToTopButton />
      <TopBar />
      <NavBar
        // foundationName="Shree Dhanlaxmi Foundation"
        instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
        instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
        address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
        logoSrc={logo}
        buttonText="Contact Us"
      />
      <div style={containerStyle}>
        <div className="container p-10 pt-3">
          <table
            style={tableStyle}
            className="table table-success table-striped"
          >
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "5px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                  }}
                >
                  <h4>Healthcare Facilities</h4>
                </td>
                <td>
                  <h5>Parent Hospital</h5>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "5px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                  }}
                >
                  <td></td>
                  <td>
                    <p style={{paddingTop: "3px"}}>Sahyadri Hospital Karad</p>
                  </td>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "5px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                  }}
                >
                  <h4>Affiliated hospitals</h4>
                </td>
                <td>
                  <p style={{paddingTop: "3px"}}>Krishna Hospital, Karad</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p style={{paddingTop: "3px"}}>Shree Hospital, Karad</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p style={{paddingTop: "3px"}}>Erram Hospital, Karad</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p style={{paddingTop: "3px"}}>Karad Hospital, Karad</p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "5px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                  }}
                >
                  <p>
                    <h5>‌Laboratory:</h5>
                  </p>
                </td>
                <td>
                  <p style={{paddingTop: "3px"}}>5</p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "5px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                  }}
                >
                  <p>
                    <h5>Class rooms:</h5>
                  </p>
                </td>
                <td>
                  <p style={{paddingTop: "3px"}}>4</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HealthFacilitiesComponent;
