import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo/clglogo.png";
import TopBar from "../TopBar.js";
import NavBar from "../NavBar.js";
import ScrollToTopButton from "../ScrollToTopButton.js";
import Footer from "../Footer.js";

const IRI = () => {
  const handleButtonClick = () => {
    console.log("Button Clicked!");
  };

  const containerStyle = {
    padding: "4%", // Add your desired padding value
  };

  const iframeStyle = {
    margin: "20px", // Add your desired margin value
  };

  return (
    <>
      <ScrollToTopButton />
      <TopBar />
      <NavBar
        // foundationName="Shree Dhanlaxmi Foundation"
        instituteName="LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY"
        instituteNameM="कै.भैय्यासाहेब घोरपडे इन्स्टिट्यूट ऑफ फिजिओथेरपी"
        address="At - Malwadi, Post - Masur Tal - Karad Dist - Satara"
        logoSrc={logo}
        buttonText="Contact Us"
        onButtonClick={handleButtonClick}
      />
      <div className="fra-container" style={containerStyle}>
        <div className="fra-content">
          <center>
            <iframe
              src={
                process.env.PUBLIC_URL +
                "assets/pdfs/iri/Application form 11-Feb-2023 12-51-19 Page 1.pdf"
              }
              frameBorder="0"
              height="200px"
              width="400px"
              title="Application Form"
              style={iframeStyle}
            ></iframe>
            <iframe
              src={
                process.env.PUBLIC_URL +
                "assets/pdfs/iri/Institute level Round vacancy list 11-Feb-2023 12-39-09.pdf"
              }
              frameBorder="0"
              height="200px"
              width="400px"
              title="Vacancy List"
              style={iframeStyle}
            ></iframe>
            <iframe
              src={
                process.env.PUBLIC_URL +
                "assets/pdfs/iri/Schedule for Institute Level Round for BPTH Course A-Y- 2022-2023 11-Feb-2023 12-40-27.pdf"
              }
              frameBorder="0"
              height="200px"
              width="400px"
              title="Schedule"
              style={iframeStyle}
            ></iframe>
          </center>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IRI;
