// Navbar.js
import React from "react";
import Logo from "./Logo.js";
import MenuBar from "./MenuBar.js";
import {Link} from "react-router-dom";

const NavBar = ({
  foundationName,
  instituteName,
  instituteNameM,
  address,
  logoSrc,
  buttonText,
  onButtonClick,
}) => {
  return (
    <>
      <div className="NavBar-container">
        <div className="navbar">
          <Logo logoSrc={logoSrc} altText={instituteName} />
          <div className="logo-right-section">
            <div className="left-section">
              <div className="head-section">
                <span className="institute-name1">{foundationName}</span>
                <span className="institute-name2">{instituteName}</span>
                <span className="institute-name3">{instituteNameM}</span>
                <span className="institute-name4">{address}</span>
              </div>
              <div className="right-section">
              <Link to="/contact">
                {" "}
                <button onClick={onButtonClick}>{buttonText}</button>{" "}
              </Link>
                
              </div>
            </div>
            <div className="down-section">
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
