import React from "react";
import "./css/Footer.css";
import logo from "./assets/logo/trustlogo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container-footer">
          <div className="footer-sections">
            <div className="footer-containers">
              <div className="footer-inner">
                <img
                  src={logo}
                  alt=""
                  style={{width: "120px", height: "150px"}}
                />
                <h4>LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY</h4>
                <div className="social-icons">
                  <a href="https://www.facebook.com">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href="https://twitter.com">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href="https://www.google.com">
                    <FontAwesomeIcon icon={faGoogle} />
                  </a>
                </div>
                <div className="social-icons">
                  {/* Add valid URLs or use buttons instead */}
                  <button className="fa fa-linkedin"></button>
                  <button className="fa fa-youtube"></button>
                  <button className="fa fa-instagram"></button>
                </div>
              </div>
            </div>
            <div className="footer-containers">
              <div className="footer-container-contents">
                <ul>
                <h4>Quick Access</h4>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">About Us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/">Terms of Services</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-sections">
            <div className="footer-containers">
              <div className="footer-container-contents">  
                <ul>
                <h4>Important Links</h4>
                  <li>
                    <a href="/muhsmandate">MUHS Mandate</a>
                  </li>
                  <li>
                    <a href="/FRA">FRA Fee Proposal</a>
                  </li>
                  <li>
                    <a href="/iri">Institutional Round Information</a>
                  </li>
                  <li>
                    <a href="/">Affilation</a>
                  </li>
                  <li>
                    <a href="/instituteinfotable">Institute</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-containers">
              <div className="footer-container-contents">
                <ul>
                <h4>Quick Links</h4>
                  <li>
                    <a href="/">Admission</a>
                  </li>
                  <li>
                    <a href="/">Courses</a>
                  </li>
                  <li>
                    <a href="/">Faculty</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/">Gallery</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer1">
        <p>copyright 2024 © LATE BHAIYYASAHEB GHORPADE INSTITUTE OF PHYSIOTHERAPY</p>
      </div>
    </div>
  );
};

export default Footer;
