// AnnexureList.js
import React, { useState } from 'react';

const AnnexureList = ({ annexures, onSelectAnnexure }) => {
  const [openAnnexure, setOpenAnnexure] = useState(null);
  const [hoveredAnnexure, setHoveredAnnexure] = useState(null);

  const handleAnnexureClick = (annexure) => {
    setOpenAnnexure((prevOpenAnnexure) =>
      prevOpenAnnexure === annexure ? null : annexure
    );
    onSelectAnnexure(annexure);
  };

  const handleTitleClick = () => {
    setOpenAnnexure(null); // Close the currently open annexure
    onSelectAnnexure(null); // Notify the parent component of the deselection
  };

  const handleHoverAnnexure = (annexure) => {
    setHoveredAnnexure(annexure);
  };

  return (
    <div className='list_annex_content'>
      <h2 onClick={handleTitleClick} className="annexure-title">
        Annexures
      </h2>
      <ul className='list_annex_list'>
        {annexures.map((annexure) => (
          <li
            key={annexure.number}
            onClick={() => handleAnnexureClick(annexure)}
            onMouseEnter={() => handleHoverAnnexure(annexure)}
            onMouseLeave={() => handleHoverAnnexure(null)}
            className={`annexure-item ${openAnnexure === annexure ? 'open' : ''} ${hoveredAnnexure === annexure ? 'hover' : ''}`}
          >
            Annexure {annexure.number}
            {openAnnexure === annexure && (
              <ul>
                {annexure.years.map((yearData) => (
                  <li key={yearData.year}>
                    {yearData.year}
                    <ul>
                      {yearData.files.map((file) => (
                        <li key={file.id}>{file.name}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnnexureList;
